<template>
  <main class="project-edit">
    <div class="horiz-container">
      <div class="project-edit__header">
        <FancyBreadcrumbs class="project-view__breadcrumbs" :links="[
          { name: 'Проекты', to: { name: 'project-list' } },
          ...(isEditing ? [{ name: data.title, to: { name: 'project-view', params: { id: data.id } } }] : []),
          { name: pageTitle, to: isEditing ? { name: 'project-edit', params: { id: data.id } } : { name: 'project-create' } }
        ]" />
        <h1 class="page-title">{{ pageTitle }}</h1>
      </div>
      <div class="project-edit__body default-grid">
        <ProjectForm
            class="project-edit__form"
            v-model="form"
            v-model:step="step"
            :data="data"
            :errors="errors"
            :is-editing="isEditing"
        />
      </div>
      <div v-if="isMainFilled" class="project-edit__footer" :class="{ pending }">
        <template v-if="isEditing">
          <BaseButton @click="submit">Сохранить</BaseButton>
          <BaseButton outlined :to="{ name: 'project-view', params: { id: data.id } }">Отмена</BaseButton>
        </template>
        <BaseButton v-else color-primary @click="submit">Создать проект</BaseButton>
      </div>
      <Navbar>
        <template v-if="isEditing">
          <BaseButton small transparent :to="{ name: 'project-view', params: { id: data.id } }">Отмена</BaseButton>
          <BaseButton small @click="submit">Сохранить</BaseButton>
        </template>
        <template v-else>
          <BaseButton v-if="step > 0" small transparent @click="step -= 1"><IconChevronLeft class="icon" />Назад</BaseButton>
          <div v-else />

          <BaseButton v-if="step === 1" small color-primary @click="submit" :class="{ pending }">Создать проект</BaseButton>
          <BaseButton v-else small transparent @click="step += 1" :disabled="!isMainFilled">Вперед<IconChevronRight class="icon" /></BaseButton>
        </template>
      </Navbar>
    </div>
  </main>
</template>

<script setup>
import { IconChevronRight, IconChevronLeft } from '@tabler/icons-vue'
import { createProject, editProject, getProject } from '~/api/projects'

const router = useRouter()
const route = useRoute()
const user = inject('user')

const {
  form,
  errors,
  loading: pending,
  submit
} = useForm(
  d => isEditing.value ? editProject(data.value.id, d) : createProject(d),
  response => router.replace({ name: 'project-view', params: { id: isEditing.value ? data.value.id : response.id } })
)
form.value.vacancies = []

const step = ref(0)
const isMainFilled = computed(() => form.value.title && form.value.description)

const isEditing = computed(() => route.name === 'project-edit')
const { data } = await useAsyncData(async () => isEditing.value ? await getProject(route.params.id) : null)
if (isEditing.value) {
  if (!data.value) throw createNotFoundError()
  if (data.value.owner_id !== user.value.id) throw createForbiddenError()

  ;['title', 'description', 'period'].forEach(k => form.value[k] = data.value[k])
  form.value.vacancies = data.value.vacancies.map(data => {
    const vacancy = {}

    ;['id', 'title', 'description', 'payment', 'payment_type', 'busyness', 'skill_level_id']
      .forEach(k => vacancy[k] = data[k])
    vacancy.skills = data.skills.map(s => s.id)

    return vacancy
  })
}

const pageTitle = computed(() => isEditing.value ? 'Изменить проект' : 'Создать проект')
useHead({ title: pageTitle })
</script>

<style scoped lang="scss">
.project-edit {
  &__header {
    margin-bottom: 64px;
    @media (max-width: 920px) {
      display: none;
    }
  }
  &__form {
    grid-column: span 9;
    @media (max-width: 920px) {
      grid-column: span 12;
    }
  }
  &__footer {
    margin-top: 64px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    @media (max-width: 920px) {
      display: none;
    }
  }
}
</style>
